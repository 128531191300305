import { FileUpload, TextField } from '@portal/ui';

const PartnerFields = () => {
  return (
    <div className="flex flex-col">
      <div className="mb-4">
        <TextField id="name" label="Name" />
      </div>
      <div className="mb-4">
        <TextField id="primaryEmail" label="Primary email" />
      </div>
      <div className="mb-4">
        <TextField id="subdomain" label="Subdomain" />
      </div>
      <div className="mb-4">
        <TextField id="licenseTotal" label="License total" />
      </div>
      <div className="flex flex-col gap-2.5 mb-4">
        <label className="block text-sm font-medium text-gray-700" htmlFor="ProfileImage">
          Logo
        </label>
        <div className="w-4/5 ml-auto">
          <FileUpload onChange={() => console.log('')} />
        </div>
      </div>
    </div>
  );
};

export default PartnerFields;

import { Button, Drawer, Formik } from '@portal/ui';
import { Form } from 'formik';
import { PartnerFormType } from 'views/Partners/PartnersPage';
import ParnterFields from './PartnerFields';
import { newPartnerSchema } from '../validation/partnerSchema';

export type PartnerProps = {
  open: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  partner: PartnerFormType;
};

const PartnerForm = ({ open, onClose, onSubmit, partner }: PartnerProps) => {
  return (
    <>
      <Drawer open={open} onClose={onClose}>
        <Formik
          initialValues={
            partner
              ? partner
              : {
                  name: '',
                  primaryEmail: '',
                  licenseTotal: 0,
                  subdomain: '',
                }
          }
          validationSchema={newPartnerSchema}
          onSubmit={(values) => {
            onSubmit(values);
          }}
        >
          <Form className="h-full flex flex-col">
            <Drawer.Title title={partner ? `Edit ${partner.name}` : 'Add a partner'} onClose={onClose} />
            <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
              <ParnterFields />
            </div>
            <Drawer.Footer>
              <Button title="create partner" displayType="primary" type="submit">
                Create
              </Button>
            </Drawer.Footer>
          </Form>
        </Formik>
      </Drawer>
    </>
  );
};

export default PartnerForm;

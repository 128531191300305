import { useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { classNames, isActivePath } from '../../utils/helper';
import Avatar from '../../display/Avatar';
import User from '../../types/UserType';
import { getInitials } from '@portal/utils/string';
import Icons from '../../assets/icons';
import { createPortal } from 'react-dom';
import SubMenu from './SubMenu';
import Dropdown, { DropdownItem } from '../Dropdown';

export type NavigationProps = {
  name: string;
  link: React.ReactNode;
  pathName: string;
  subMenu?: NavigationProps[];
  forceActive?: boolean;
};

export type SidebarProps = {
  navigation: NavigationProps[];
  subNavigation?: NavigationProps[];
  footerNavigation?: NavigationProps[];
  subNavigationTitle?: string;
  logo?: string;
  user?: User;
  logout?: () => void;
  userDropdownItems?: DropdownItem[];
};

const Sidebar = ({
  user,
  navigation,
  logo,
  subNavigationTitle,
  footerNavigation = [],
  subNavigation = [],
  userDropdownItems,
}: SidebarProps) => {
  const [domReady, setDomReady] = useState(false);
  useEffect(() => {
    if (!domReady) setDomReady(true);
  }, [domReady]);

  const subMenu = document.getElementById('subMenu');
  return (
    <Disclosure as="nav" className="flex relative z-50 h-full flex-col" id="sidebar" defaultOpen>
      {() => (
        <>
          <div className="flex w-60 sticky top-0 flex-col h-full md:h-screen justify-between sidebar">
            <div className="space-y-3">
              <div className="flex items-center px-1 py-3">
                {logo && (
                  <div className="flex-shrink-0">
                    <img className="h-10 w-10 m-3" src={logo} alt="Your Company" />
                  </div>
                )}
              </div>
              <div className="flex-1">
                <ul className="pb-4 m-3 space-y-1 ml-3 mr-3 text-sm">
                  {navigation.map((item, idx) => (
                    <Link key={idx} to={item?.pathName}>
                      <li className="rounded-sm">
                        <Disclosure.Button
                          className={classNames(
                            isActivePath(item.pathName) || item.forceActive
                              ? 'rounded-md nav-active'
                              : 'hover:text-gray-700 nav',
                            'flex items-center px-3 py-2 mt-1 hover:bg-gray-100 space-x-3 rounded-md w-full text-sm font-medium'
                          )}
                        >
                          <span>{item.link}</span>
                          {item.subMenu &&
                            item.subMenu?.length > 0 &&
                            domReady &&
                            createPortal(
                              <SubMenu open={isActivePath(item.pathName)} navigation={item.subMenu} />,
                              subMenu as HTMLElement
                            )}
                        </Disclosure.Button>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>

              <div className="flex-1">
                <h2 className="space-y-1 px-2 text-gray-600 font-medium text-xs ml-3 mr-3"> {subNavigationTitle}</h2>
                <ul className="pb-4 m-3 space-y-1 ml-3 mr-3 text-sm">
                  {subNavigation.map((item, idx) => (
                    <Link key={idx} to={item?.pathName}>
                      <li className="rounded-sm">
                        <Disclosure.Button
                          className={classNames(
                            isActivePath(item.pathName) ? 'nav-active rounded-md' : 'hover:text-gray-700 nav',
                            'flex items-center px-3 py-2 mt-1 hover:bg-gray-100 space-x-3 rounded-md w-full text-sm font-medium'
                          )}
                        >
                          <span>{item.link}</span>
                        </Disclosure.Button>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
            <div className="items-end">
              <ul className="pt-2 pb-4 space-y-1 text-sm ml-3 mr-3">
                {footerNavigation.map((item, idx) => (
                  <li className="rounded-sm" key={idx}>
                    <Link to={item?.pathName}>
                      <Disclosure.Button
                        className={classNames(
                          isActivePath(item.pathName) ? 'rounded-md nav-active' : 'hover:text-gray-700 nav',
                          'flex items-center px-3 py-2 hover:bg-gray-100 space-x-3 rounded-md w-full text-sm font-semibold'
                        )}
                      >
                        <span>{item.link}</span>
                      </Disclosure.Button>
                    </Link>
                  </li>
                ))}
              </ul>
              {user && (
                <div className="user-info-navigation">
                  <Dropdown items={userDropdownItems ?? []}>
                    <div className=" w-full flex items-center justify-between m-3">
                      <div className="flex gap-2 items-center">
                        <Avatar size="x_small" imageUrl={user.imageUrl} initials={getInitials(user.name)} />
                        <p className="text-gray-900 font-semibold text-sm user-name">{user.name}</p>
                      </div>
                      <Icons.ChevronSelector className="chevron-icon" />
                    </div>
                  </Dropdown>
                </div>
              )}
            </div>
          </div>
          <div id="subMenu"></div>
        </>
      )}
    </Disclosure>
  );
};

export default Sidebar;

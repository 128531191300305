import { RequireAuth } from 'components/routing';
import { Routes, Route } from 'react-router-dom';

import LoginPage from 'views/Login/LoginPage';

import PartnersPage from 'views/Partners/PartnersPage';

import ConfirmPasswordPage from 'views/ResetPassword/PasswordSetPage';
import ResetPasswordPage from 'views/ResetPassword/ResetPasswordPage';
import AdminsPage from 'views/Admins/AdminsPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <RequireAuth>
            <PartnersPage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/passwords/new" element={<ResetPasswordPage />} />
      <Route path="/passwords/confirm" element={<ConfirmPasswordPage />} />
      <Route
        path="/accounts/partners"
        element={
          <RequireAuth>
            <PartnersPage />
          </RequireAuth>
        }
      />
      <Route
        path="/users/admins"
        element={
          <RequireAuth>
            <AdminsPage />
          </RequireAuth>
        }
      />
    </Routes>
  );
};

export default AppRoutes;

import { Helmet } from 'react-helmet';
import { Header } from '.';
import { Breadcrumb, Icons } from '@portal/ui';
import { useLocation } from 'react-router-dom';
import { capitalizeText } from '@portal/utils/string';

const Layout = ({ children }: { children: JSX.Element }) => {
  const { pathname } = useLocation();

  return (
    <>
      <Helmet titleTemplate="%s | Growthware">
        <title></title>
      </Helmet>
      <div className="flex">
        <Header />
        <div className="min-h-full w-full">
          <main>
            <div className="pt-10 pl-10">
              <Breadcrumb>
                <Breadcrumb.Item href="/">
                  <Icons.Homeline color="#5D6B98" />
                </Breadcrumb.Item>
                {pathname === '/' ? (
                  <Breadcrumb.Item isCurrent>Dashboard</Breadcrumb.Item>
                ) : (
                  pathname
                    .replace('/', '')
                    .split('/')
                    .map((item) => <Breadcrumb.Item isCurrent>{capitalizeText(item)}</Breadcrumb.Item>)
                )}
              </Breadcrumb>
            </div>
            <div className="relative">{children}</div>
          </main>
        </div>
      </div>
    </>
  );
};

export { Layout };

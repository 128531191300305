import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Keys, localStorage } from 'libs/local-storage';

type UserState = {
  attributes: {
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
  } | null;
  session: {
    status: 'loading' | 'signed-in' | 'signed-out';
    accessToken: string | null;
    refreshToken: string | null;
  };
};

const initialState: UserState = {
  attributes: null,
  session: {
    status: 'signed-out',
    accessToken: null,
    refreshToken: null,
  },
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAttributes: (state, action: PayloadAction<UserState['attributes']>) => {
      state.attributes = action.payload;
    },
    setStatus: (state, action: PayloadAction<UserState['session']['status']>) => {
      state.session.status = action.payload;
    },
    updateSession: (state, action: PayloadAction<Partial<UserState['session']>>) => {
      state.session = {
        ...state.session,
        ...action.payload,
      };
      localStorage.set(Keys.accessToken, state.session.accessToken);
      localStorage.set(Keys.refreshToken, state.session.refreshToken);
    },
    logout: () => {
      localStorage.remove(Keys.accessToken);
      localStorage.remove(Keys.refreshToken);
      return { ...initialState };
    },
    reset: () => {
      return { ...initialState };
    },
  },
});

export const userReducer = slice.reducer;
export const { setAttributes, setStatus, updateSession, logout, reset } = slice.actions;
export type { UserState };

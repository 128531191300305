import { useState, useEffect } from 'react';
import { Formik, Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/hooks';
import { signInWithEmail } from 'redux/thunks/user';
import { loginSchema } from 'components/validation/loginSchema';
import logo from 'assets/growthware_logo.png';
import { Button, TextField } from '@portal/ui';

const LoginPage = () => {
  const dispatch = useDispatch();
  const status = useSelector((state) => state.user.session.status);
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const errorPresent = error && error !== '';

  const signInClicked = async (username: string, password: string) => {
    try {
      await dispatch(signInWithEmail(username, password));
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('verify');
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    if (status === 'signed-in') {
      navigate('/');
    }
  }, [status]);

  return (
    <div className="min-h-full h-screen	 grid grid-cols-2 gap-4">
      <div className="py-12 flex-grow">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">Growthware Admin</h2>
          <p className="mt-2 text-center text-sm text-gray-600">Welcome back! Please enter your details.</p>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="py-8 px-4 sm:rounded-lg sm:px-10">
            {errorPresent && (
              <div className="border-red-400 bg-red-50 p-4 my-4">
                <div className="flex">
                  <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                  </div>
                </div>
              </div>
            )}
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema}
              onSubmit={(values) => {
                signInClicked(values.email, values.password);
              }}
            >
              <Form className="space-y-6">
                <TextField id="email" label="Email" type="email" />
                <TextField id="password" label="Password" type="password" />
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 bg-white text-purple-600 focus:ring-purple-500"
                    />
                    <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                      Remember for 30 days
                    </label>
                  </div>

                  <div className="text-sm">
                    <a href="/passwords/new" className="font-medium text-purple-600 hover:text-purple-500">
                      Forgot password?
                    </a>
                  </div>
                </div>

                <div>
                  <button
                    className="text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border border-steel-300 cursor-pointer gap-1 !hover:bg-primary-600 !bg-primary !primary-btn !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white"
                    type="submit"
                  >
                    Log in
                  </button>
                </div>
              </Form>
            </Formik>
          </div>
        </div>
      </div>
      <div className="flex-grow w-full bg-[url('assets/login_section.png')] bg-cover"></div>
    </div>
  );
};

export default LoginPage;

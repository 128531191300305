import { PageHeader } from '@portal/ui';

const AdminsPage = () => {
  return (
    <div className="p-10">
      <PageHeader title="Admins" />
    </div>
  );
};

export default AdminsPage;

import { Disclosure } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { classNames, isActivePath } from '../../utils/helper';

export type NavigationProps = {
  name: string;
  link: React.ReactNode;
  pathName: string;
  subMenu?: NavigationProps[];
};

export type SidebarProps = {
  navigation: NavigationProps[];
  open: boolean;
};

const SubMenu = ({ navigation, open }: SidebarProps) => {
  return (
    <Disclosure
      as="nav"
      className={`transition-all duration-200 flex ${open ? 'w-44' : 'w-0 h-0 invisible absolute top-0 left-0'}`}
      defaultOpen={false}
    >
      {() => (
        <>
          <div
            className={` ease-in-out md:flex flex-col ${
              open ? 'w-44' : 'w-0 h-0 invisible absolute'
            }  justify-between h-screen shadow sub-sidebar`}
          >
            <div className="space-y-3">
              <div className="flex-1">
                <ul className="pb-4 m-3 space-y-1 ml-3 mr-3 text-sm">
                  {navigation.map((item, idx) => (
                    <Link to={item?.pathName}>
                      <li className="rounded-sm" key={idx}>
                        <Disclosure.Button
                          className={classNames(
                            isActivePath(item.pathName) ? 'rounded-md nav-active' : '  hover:text-primary nav',
                            'flex items-center p-2 mt-2 space-x-3 rounded-md w-full font-medium text-sm'
                          )}
                        >
                          <span>{item.link}</span>
                        </Disclosure.Button>
                      </li>
                    </Link>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </Disclosure>
  );
};

export default SubMenu;

export type NavigationLinkProps = {
  link: React.ReactNode;
  icon?: React.ReactNode;
};

const NavigationLink = ({ link, icon }: NavigationLinkProps) => {
  return (
    <div className="flex w-full gap-3">
      {icon && icon}
      {link}
    </div>
  );
};

export default NavigationLink;

import { useState } from 'react';
import { Formik, Form } from 'formik';
import { resetPasswordSchema } from 'components/validation/resetPasswordSchema';
import { Button, Icons, TextField, passwordValidation } from '@portal/ui';

const ResetPasswordPage = () => {
  const [error, setError] = useState('');
  const [validation, setValidation] = useState(false);
  const errorPresent = error && error !== '';
  const handleSubmit = (newPassword: string, confirmPassword: string) => {
    const valid = passwordValidation(newPassword, confirmPassword);
    if (valid) {
      setValidation(true);
    }
  };
  return (
    <div className="min-h-full grid grid-cols-2 gap-4">
      <>
        <div className={`flex min-h-full flex-col  justify-center items-center py-12 sm:px-6 lg:px-8`}>
          <div className="sm:mx-auto sm:w-full sm:max-w-md flex flex-col items-center">
            <div className="inline-flex items-center justify-center rounded-full w-10 h-10 text-gray-900 p-2 bg-purple-100">
              <Icons.Key className="h-12 w-auto text-purple-600" />
            </div>
            <h2
              className="mt-6 text-center text-3xl font-bold tracking-tight import logo from 'assets/growthware_logo.png';
"
            >
              Set your password
            </h2>
            <p className="mt-2 text-center text-sm text-gray-600">
              Welcome to Growthware. Create a password to finish setting up your account.
            </p>
          </div>
          <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
            <div className=" py-8 px-4  sm:rounded-lg sm:px-10">
              {errorPresent && (
                <div className="border-red-400 bg-red-50 p-4 my-4">
                  <div className="flex">
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                  </div>
                </div>
              )}
              <Formik
                initialValues={{
                  newPassword: '',
                  confirmPassword: '',
                }}
                validationSchema={resetPasswordSchema}
                onSubmit={(values) => {
                  handleSubmit(values.newPassword, values.confirmPassword);
                }}
              >
                <Form className="space-y-6">
                  <TextField id="newPassword" label="Password" type="password" />
                  <TextField id="confirmPassword" label="Reset Password" type="password" />
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="remember-me"
                        name="remember-me"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 bg-primary-background-200 text-purple-600 focus:ring-purple-500"
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        I agree to the{' '}
                        <a href="#" className="font-medium text-purple-600 hover:text-purple-500">
                          terms of services.
                        </a>
                      </label>
                    </div>
                  </div>

                  <div>
                    <button
                      title="set password"
                      className="text-sm rounded-md shadow-sm font-medium items-center inline-flex px-4 py-2 border border-steel-300 cursor-pointer gap-1 !hover:bg-primary-600 !bg-primary !primary-btn !border-primary-600 !hover:border-primary-700 !focus:ring-primary-500 !text-white"
                      type="submit"
                    >
                      Set password
                    </button>
                  </div>
                </Form>
              </Formik>
            </div>
          </div>
        </div>
      </>
      <div className="flex-grow w-full bg-[url('assets/login_section.png')] bg-cover"></div>
    </div>
  );
};

export default ResetPasswordPage;

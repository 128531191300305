import { useState } from 'react';
import { Button, PageHeader, Badge, BadgeType, Table, EmptyState, Icons, Notifier, RowActionMenu } from '@portal/ui';
import { capitalizeText } from '@portal/utils/string';
import PartnerForm from 'components/partner/PartnerForm';
import moment from 'moment';

import { useNavigate } from 'react-router-dom';

const badgeMappings: Record<string, BadgeType> = {
  Active: BadgeType.Success,
  Deactivated: BadgeType.Cancelled,
};

export interface PartnerFormType {
  name: string;
  primaryEmail: string;
  licenseTotal: number;
  licenseRemaining: number;
  status: string;
  id: string;
}

const initialPartner: PartnerFormType = {
  id: '',
  name: '',
  primaryEmail: '',
  licenseTotal: 0,
  licenseRemaining: 0,
  status: 'Active',
};

const PartnersPage = () => {
  const [openModal, setModalOpen] = useState(false);
  const [data, setData] = useState<PartnerFormType[]>([]);
  const [partner, setPartner] = useState<PartnerFormType>(initialPartner);
  const navigate = useNavigate();

  const rowActions = [{ name: 'Edit', action: (row: any) => editPartner(row) }];

  const editPartner = (rowData: any) => {
    setPartner({ ...rowData.cell.row.original });
    setModalOpen(true);
  };

  const columns = [
    {
      name: 'ID',
      id: 'id',
    },
    {
      id: 'name',
      name: 'Name',
    },
    {
      id: 'primaryEmail',
      name: 'Primary email',
    },
    {
      id: 'licenseTotal',
      name: 'Licenses total',
    },
    { id: 'licenseRemaining', name: 'Licenses remaining' },
    {
      id: 'createdAt',
      name: 'Created',
      cell: (info: any) => moment(info.getValue()).format('MMM D, YYYY'),
    },
    {
      id: 'status',
      name: 'Status',
      cell: (info: any) => <Badge text={capitalizeText(info.getValue())} type={badgeMappings[info.getValue()]} />,
    },
    {
      id: 'actions ',
      name: '',
      cell: (info: any) => <RowActionMenu rowActions={rowActions} row={info} />,
    },
  ];

  const onSubmit = (values: any) => {
    if (partner) {
      Notifier.success('Partner updated successfully');

      setData([...data]);
      setPartner(initialPartner);
    } else {
      Notifier.success('Partner created successfully');
    }
    setModalOpen(false);
  };

  return (
    <div className="p-10">
      <PageHeader
        title="Partners"
        actions={[
          <Button title="add partner" onPress={() => setModalOpen(true)} displayType="primary">
            <Icons.Plus className="w-5 h-5" aria-hidden="true" color="white" /> Add partner
          </Button>,
        ]}
      />
      <div className="py-6">
        <Table
          columns={columns}
          data={data}
          showSearch
          pagination
          noDataElement={
            <EmptyState
              title={'No partners yet'}
              subTitle={'Get started by adding a new partner'}
              btnText={'Add Partner'}
              onClick={() => setModalOpen(true)}
              icon={<Icons.AddContact />}
            />
          }
        />
      </div>
      <PartnerForm open={openModal} onClose={() => setModalOpen(false)} onSubmit={onSubmit} partner={partner} />
    </div>
  );
};

export default PartnersPage;

import { Link } from 'react-router-dom';
import { SideNavbar, Icons, NavigationLink } from '@portal/ui';
import logo from 'assets/growthware_logo_high_res.png';
import { Cog8ToothIcon } from '@heroicons/react/24/outline/esm/index';
import { useDispatch, useSelector } from 'redux/hooks';
import { logout } from 'redux/reducers/user';

const Header = () => {
  const attributes = useSelector((state) => state.user.attributes);
  const dispatch = useDispatch();
  const navigation = [
    {
      name: 'Dashboard',
      link: <NavigationLink link={<Link to="/">Dashboard</Link>} icon={<Icons.Homeline />} />,
      pathName: '/',
    },
    {
      name: 'Accounts',
      link: (
        <NavigationLink link={<Link to="/accounts/partners">Accounts</Link>} icon={<Icons.Cube color="#B692F6" />} />
      ),
      pathName: '/accounts/partners',
      subMenu: [
        {
          name: 'Partners',
          link: <NavigationLink link={<Link to="/accounts/partners">Partners</Link>} />,
          pathName: '/accounts/partners',
        },
        {
          name: 'Merchants',
          link: <NavigationLink link={<Link to="/accounts/merchants">Merchants</Link>} />,
          pathName: '/accounts/merchants',
        },
      ],
    },
    {
      name: 'Users',
      link: <NavigationLink link={<Link to="/users/admins">Users</Link>} icon={<Icons.Users color="#B692F6" />} />,
      pathName: '/users/admins',
      subMenu: [
        {
          name: 'Admins',
          link: <NavigationLink link={<Link to="/users/admins">Admins</Link>} />,
          pathName: '/users/admins',
        },
      ],
    },
  ];

  return (
    <div>
      <SideNavbar
        logo={logo}
        navigation={navigation}
        logout={() => dispatch(logout())}
        user={{
          name: attributes?.name ?? '',
          email: attributes?.email ?? '',
          imageUrl: attributes?.avatar ?? '',
        }}
        footerNavigation={[
          {
            name: 'Settings',
            link: (
              <NavigationLink
                link={<Link to="/settings">Settings</Link>}
                icon={<Cog8ToothIcon className=" block h-5 w-5 icon" aria-hidden="true" />}
              />
            ),
            pathName: '/settings',
          },
        ]}
      />
    </div>
  );
};

export { Header };
